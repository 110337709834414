import axios from 'axios'
import api from '@/utils/endpoints'
import dotenv from 'dotenv'

dotenv.config()

const headers = {
  Accept: 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
}

export const get = params => new Promise((resolve, reject) => {
  const { date, invoiceID } = params
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.report.get}?date=${date}&invoiceId=${invoiceID}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const getStatics = params => new Promise((resolve, reject) => {
  const { date, invoiceID } = params
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.report.getStatics}?date=${date}&invoiceId=${invoiceID}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})
