<template>
  <b-card
    no-body
    class="card-statistics"
    style="min-height: 270px;"
  >

    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <!-- <b-card-text class="font-small-2 mr-25 mb-0">
        Updated 1 month ago
      </b-card-text> -->
    </b-card-header>

    <b-card-body class="statistics-body">

      <b-row>

        <b-col md="4">
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="success"
              >
                <feather-icon
                  size="24"
                  icon="DollarSignIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                Total Actual Cost
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ total_actual_cost.toFixed(2) }}$
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col md="4">
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="info"
              >
                <feather-icon
                  size="24"
                  icon="FileTextIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                Total Estimated Cost
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ total_estimated_cost.toFixed(2) }}$
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col md="4">
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="warning"
              >
                <feather-icon
                  size="24"
                  icon="MinusCircleIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                Total Difference
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ total_difference.toFixed(2) }}$
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    // eslint-disable-next-line vue/prop-name-casing
    total_estimated_cost: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/prop-name-casing
    total_actual_cost: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/prop-name-casing
    total_difference: {
      type: Number,
      default: 0,
    },
  },
}
</script>
