<template>
  <div>
    <b-row class="d-flex flex-wrap">
      <b-col md="6">
        <!-- report total statistic view -->
        <report-statistic
          :total_estimated_cost="total_estimated_cost"
          :total_actual_cost="total_actual_cost"
          :total_difference="total_difference"
        />

      <!-- <line-chart-view /> -->

      </b-col>

      <b-col md="6">
        <!-- report graphic view -->
        <report-graph-view
          :statics="statics"
        />

      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card-code
          title=""
          no-body
        >
          <!-- filter option -->
          <!-- <b-row class="mx-1">
          
            <b-col md="6">
              <b-form-group
                label="Custom Date"
                label-for="mc-date"
              >
                <b-form-datepicker
                  id="mc-date"
                  v-model="filter.date"
                  class="mb-1"
                  placeholder="Choose a Invoice Date"
                  menu-class="w-100"
                  calendar-width="100%"
                  @input="filterChange()"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Invoice ID"
                label-for="mc-invoiceId"
              >
                <b-form-input
                  id="mc-invoiceId"
                  v-model="filter.invoiceID"
                  class="mb-1"
                  type="search"
                  placeholder="Enter QB Invoice ID or Amazon Invoice Number"
                  @input="filterChange()"
                />
              </b-form-group>
            </b-col>

          </b-row> -->

          <!-- report table  -->
          <report-table
            :total_estimated_cost="total_estimated_cost"
            :total_actual_cost="total_actual_cost"
            :total_difference="total_difference"
            :items="reports"
            @pageChange="changePage"
            @changeAmazonInvoice="changeAmazonInvoice"
          />
        
        </b-card-code>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol,
  // BFormGroup, BFormDatepicker, BFormInput,
} from 'bootstrap-vue'
import { debounce } from 'debounce'
import * as reportService from '@/services/report'
import ReportTable from './Table.vue'
import ReportStatistic from './StatisticView.vue'
import ReportGraphView from './GraphView.vue'
// import LineChartView from './LineChartView.vue'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    // BFormGroup,
    // BFormDatepicker,
    ReportTable,
    ReportStatistic,
    ReportGraphView,
    // LineChartView,
  },
  data() {
    return {
      reports: [],
      total_estimated_cost: 0,
      total_actual_cost: 0,
      total_difference: 0,
      statics: null,
      filter: {
        date: '',
        invoiceID: '',
      },
    }
  },
  created() {
    this.getReports()
    this.getStatics()
  },
  methods: {
    // get Report Lists
    getReports() {
      this.$store.commit('set_loading_action', true)
      reportService.get(this.filter)
        .then(res => {
          this.reports = res.data.reports
          this.total_estimated_cost = res.data.total_estimated_cost
          this.total_actual_cost = res.data.total_actual_cost
          this.total_difference = res.data.total_difference
          this.$store.commit('set_loading_action', false)
        })
        .catch(() => {
          this.$store.commit('set_loading_action', false)
        })
    },

    async getStatics() {
      const res = await reportService.getStatics(this.filter)
      if (res.data.success) {
        this.statics = res.data.statics
      }
    },

    // eslint-disable-next-line func-names
    filterChange: debounce(function () {
      console.log('this is filter params =====>', this.filter)
    }, 500),

    changePage(e) {
      console.log('e', e)
    },

    changeAmazonInvoice(ev) {
      this.filter.invoiceID = ev
      this.getReports()
      this.getStatics()
    },
  },
}
</script>
