<template>
  <b-card style="min-height: 270px;">
    <b-row>
      <b-col cols="4">
        <b-card-title class="mb-1">
          <h3 class="font-weight-bold">
            Earnings
          </h3>
        </b-card-title>
        <div class="font-small-4">
          This Month
        </div>
        <h5 class="mb-1 text-success">
          $ {{ statics ? statics.actual_cost1.toFixed(2) : '' }}
        </h5>
        <b-card-text class="text-muted font-small-4">
          <span
            v-if="statics"
            class="font-weight-bolder text-warning"
          >
            {{ ((statics.actual_cost1 - statics.actual_cost2) * 100 / statics.actual_cost2).toFixed(2) }}%
          </span>
          <span> more earnings than last month.</span>
        </b-card-text>
      </b-col>

      <b-col md="8">
        <vue-apex-charts
          v-if="statics"
          type="donut"
          height="250"
          :options="donutChart.chartOptions"
          :series="[Number(statics.actual_cost1.toFixed(2)), Number(statics.difference1.toFixed(2))]"
        />
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardText, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
  },
  props: {
    statics: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      donutChart: {
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            '#28c76f',
            '#ff9f43',
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `$${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.2rem',
                    label: '',
                    formatter() {
                      return ''
                    },
                  },
                },
              },
            },
          },
          labels: ['Matched', 'Missing'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    }
  },
}
</script>
<style lang="scss">
// @import '@core/scss/vue/libs/chart-apex.scss';
</style>
