<template>
  <b-card>
    <b-row class="mb-2">
      <b-col
        md="12"
      >
        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :fields="fields"
          :items="items"
          :tbody-tr-class="rowClass"
        >
          <!-- h_base_rate -->
          <template #cell(h_base_rate)="row">
            <span v-if="row.item.history">
              {{ row.item.history.base_rate.toFixed(2) }}$
            </span>
          </template>

          <!-- h_fuel_surcharge -->
          <template #cell(h_fuel_surcharge)="row">
            <span v-if="row.item.history">
              {{ row.item.history.fuel_surcharge.toFixed(2) }}$
            </span>
          </template>

          <!-- h_tolls -->
          <template #cell(h_tolls)="row">
            <span v-if="row.item.history">
              {{ row.item.history.tolls.toFixed(2) }}$
            </span>
          </template>

          <!-- base_rate -->
          <template #cell(base_rate)="row">
            <span v-if="row.item.base_rate">
              {{ row.item.base_rate.toFixed(2) }}$
            </span>
          </template>

          <!-- fuel_surcharge -->
          <template #cell(fuel_surcharge)="row">
            <span v-if="row.item.fuel_surcharge">
              {{ row.item.fuel_surcharge.toFixed(2) }}$
            </span>
          </template>

          <!-- tolls -->
          <template #cell(tolls)="row">
            <span v-if="row.item.tolls">
              {{ row.item.tolls.toFixed(2) }}$
            </span>
          </template>

          <!-- estimated_cost -->
          <template #cell(estimated_cost)="row">
            <span v-if="row.item.estimated_cost">
              {{ row.item.estimated_cost.toFixed(2) }}$
            </span>
          </template>

          <!-- gross_pay -->
          <template #cell(gross_pay)="row">
            <span v-if="row.item.gross_pay">
              {{ row.item.gross_pay.toFixed(2) }}$
            </span>
          </template>

          <!-- difference -->
          <template #cell(difference)="row">
            <span v-if="row.item.difference">
              {{ row.item.difference.toFixed(2) }}$
            </span>
          </template>

          <!-- action -->
          <template #cell(action)="row">
            <b-button
              v-if="!row.item.qb_invoice_id"
              variant="outline-secondary"
              @click="sendInvoice(row.item)"
            >
              Send Invoice
            </b-button>
            <span
              v-if="row.item.qb_invoice_id"
              class="text-success d-flex align-items-center"
            >
              <feather-icon
                icon="CheckCircleIcon"
                style="margin-right: 2px;"
                size="18"
              /> Invoiced
            </span>
          </template>
          
        </b-table>

      </b-col>
    </b-row>
              
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BButton,
} from 'bootstrap-vue'
import * as invoiceService from '@/services/invoice'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      fields: [
        { key: 'action', label: 'Action' },
        { key: 'block_id', label: 'Block ID' },
        { key: 'trip_id', label: 'Trip ID' },
        { key: 'load_id', label: 'Load ID' },
        { key: 'facility_sequence', label: 'Facility' },
        { key: 'h_base_rate', label: 'H - Base Rate', variant: 'info' },
        { key: 'h_fuel_surcharge', label: 'H - Fuel Surcharge', variant: 'info' },
        { key: 'h_tolls', label: 'H - Tolls', variant: 'info' },
        { key: 'base_rate', label: 'Base Rate' },
        { key: 'fuel_surcharge', label: 'Fuel Surcharge' },
        { key: 'tolls', label: 'Tolls' },
        { key: 'estimated_cost', label: 'Estimate Cost', variant: 'warning' },
        { key: 'gross_pay', label: 'Actual Cost', variant: 'success' },
        { key: 'difference', label: 'Difference', variant: 'danger' },
        { key: 'qb_invoice_id', label: 'QB Invoice ID' },
      ],
    }
  },
  mounted() {
    console.log('items', this.items)
  },
  methods: {
    rowClass(item, type) {
      const colorClass = 'table-secondary'
      if (!item || type !== 'row') { return }

      // eslint-disable-next-line consistent-return
      if ((!item.load_id && !item.trip_id) || (!item.load_id && !item.block_id)) { return colorClass }
    },

    // send invoice
    sendInvoice(value) {
      const param = {
        actual_pay: value.gross_pay,
        driver: value.driver,
        load_id: value.load_id,
        block_id: value.block_id,
        trip_id: value.trip_id,
        facility_sequence: value.facility_sequence,
        base_rate: value.base_rate,
        fuel_surcharge: value.fuel_surcharge,
        detention: value.detention,
        tonu: value.tonu,
        tolls: value.tolls,
        others: value.others,
      }
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, upload it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.commit('set_loading_action', true)
          invoiceService.sendInvoice({ payload: param })
            .then(() => {
              this.$store.commit('set_loading_action', false)
              this.$swal({
                title: 'Congratulation!',
                text: 'You sent Invoice succesfully!',
                icon: 'success',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then(() => {
                this.$router.go(this.$router.currentRoute)
              })
            })
            .catch(() => {
              this.$store.commit('set_loading_action', false)
            })
        }
      })
    },
  },
}
</script>

<style>

</style>
